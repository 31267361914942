export const staticRoutes = {
  contacts: {
    url: '/contacts',
    name: 'Contacts',
  },
  cart: {
    url: '/cart',
    name: 'Cart',
  },
  login: {
    url: '/login',
    name: 'Login',
  },
  createAccount: {
    url: '/create-account',
    name: 'Create account',
  },
  checkoutDelivery: {
    url: '/checkout/delivery',
    name: 'Delivery | Checkout',
  },
  checkoutPayment: {
    url: '/checkout/payment',
    name: 'Payment | Checkout',
  },
  checkoutReview: {
    url: '/checkout/review',
    name: 'Review | Checkout',
  },
  search: {
    url: '/search',
    name: 'Search',
  },
  home: {
    url: '/',
    name: 'Home',
  },
  forgottenPassword: {
    url: '/forgotten-password',
    name: 'Change of password',
  },
  allProducts: {
    url: 'search?q=',
    name: 'All products',
  },
  privacyPolicy: {
    url: '/privacy-policy',
    name: 'Privacy policy',
  },
  blog: {
    url: '/blog',
    name: 'Blog',
  },
}
