import { useMutation } from '@tanstack/react-query'
import { useCustomer } from '@lib/hooks/data/customer/useCustomer'

interface LupaClickEventBody {
  queryKey: string | undefined
  searchQuery?: string | string[]
  itemId: number
}

export const useLupaItemClickEvent = () => {
  const { data: customer } = useCustomer()

  return useMutation(
    async ({ queryKey, searchQuery, itemId }: LupaClickEventBody) => {
      if (!queryKey || !itemId) {
        return
      }

      await fetch('/api/lupa/events', {
        method: 'POST',
        body: JSON.stringify({
          queryKey,
          name: 'itemClick',
          searchQuery: Array.isArray(searchQuery)
            ? searchQuery?.[0] || ''
            : searchQuery || '',
          itemId: itemId.toString(),
          userId: customer?.id?.toString() || null,
        }),
      })
    }
  )
}
