'use client'

import { useSpring, a } from '@react-spring/web'
import cn from 'clsx'
import { FC, memo, ReactNode, useState } from 'react'
import useMeasure from 'react-use-measure'
import { ChevronDown } from '@components/icons'

export interface ICollapseProps {
  title: string
  children?: ReactNode
  count?: number
  classes?: {
    root?: string
    title?: string
  }
}

const Collapse: FC<ICollapseProps> = (props) => {
  const { title, count, classes, children } = props
  const [isActive, setActive] = useState(false)
  const [ref, { height: viewHeight }] = useMeasure()

  const animProps = useSpring({
    height: isActive ? viewHeight : 0,
    config: { tension: 250, friction: 32, clamp: true, duration: 150 },
    opacity: isActive ? 1 : 0,
  })

  const toggle = () => setActive((x) => !x)

  return (
    <div
      className={cn('flex flex-col border-b p-3', classes?.root)}
      aria-expanded={isActive}
    >
      <div
        tabIndex={0}
        role="button"
        className="flex flex-row items-center justify-between"
        onClick={toggle}
      >
        <h4 className={cn('flex items-center', classes?.title)}>
          {title}
          {!!count && (
            <div className="ml-2 flex h-6 w-6 items-center justify-center rounded-full bg-black text-sm font-bold text-white">
              {count}
            </div>
          )}
        </h4>
        <ChevronDown
          className={cn('transition-transform', isActive && 'rotate-180')}
        />
      </div>
      <a.div style={{ overflow: 'hidden', ...animProps }}>
        <div ref={ref} className="overflow-hidden pt-4">
          {children}
        </div>
      </a.div>
    </div>
  )
}

export default memo(Collapse)
