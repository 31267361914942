import lazy from 'next/dynamic'
import { WidgetTypeEnum } from '@lib/constants/widgets'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const map: Record<WidgetTypeEnum, any> = {
  [WidgetTypeEnum.ProductList]: lazy(
    () => import('@components/widgets/ProductCardSlider')
  ),
  [WidgetTypeEnum.HeroBanners]: lazy(() => import('@components/widgets/Hero')),
  [WidgetTypeEnum.Intro]: lazy(() => import('@components/widgets/Intro')),
  [WidgetTypeEnum.CategoryBanners]: lazy(
    () => import('@components/widgets/CategoryBanners')
  ),
  [WidgetTypeEnum.Html]: lazy(() => import('@components/widgets/HtmlWidget')),
  [WidgetTypeEnum.Faq]: lazy(() => import('@components/widgets/FaqBlock')),
  [WidgetTypeEnum.BlogList]: lazy(
    () => import('@components/widgets/BlogPostSlider')
  ),
  [WidgetTypeEnum.Text]: lazy(() => import('@components/widgets/Text')),
  [WidgetTypeEnum.Image]: lazy(() => import('@components/widgets/Image')),
  [WidgetTypeEnum.ImageCollection]: lazy(
    () => import('@components/widgets/ImageCollection')
  ),
  [WidgetTypeEnum.BrandsSlider]: lazy(
    () => import('@components/widgets/BrandsSlider')
  ),
}

function findComponentByType(type: WidgetTypeEnum) {
  return Object.prototype.hasOwnProperty.call(map, type) ? map[type] : null
}

export { map, findComponentByType }
