export enum WidgetTypeEnum {
  ProductList = 'app.product_list',
  HeroBanners = 'app.slider',
  Intro = 'app.info_line',
  CategoryBanners = 'app.category_banner',
  Html = 'app.html',
  Faq = 'app.faq',
  BlogList = 'app.blog',
  Text = 'app.text',
  Image = 'app.image',
  ImageCollection = 'app.image_collection',
  BrandsSlider = 'app.brand_slider',
}

export const DEFAULT_CACHE_TIME_SECONDS = 1 * 60
