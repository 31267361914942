'use client'

import React from 'react'
import { IWidget } from '@lib/types/widgetTypes'
import { findComponentByType } from '@lib/widgets/WidgetTypeToComponent'

interface Props {
  widget: IWidget
}

const AbstractWidget = ({ widget }: Props) => {
  const WidgetComponentInstance = findComponentByType(widget.code)

  if (!WidgetComponentInstance) {
    return null
  }

  return React.createElement(WidgetComponentInstance, {
    ...widget.data,
  })
}

export default AbstractWidget
