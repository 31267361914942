export const defaultCustomerRoute = '/customer'

export const customerRoutes = {
  profile: {
    url: `${defaultCustomerRoute}/profile`,
    name: 'Personal information',
  },
  orders: {
    url: `${defaultCustomerRoute}/orders`,
    name: 'Orders',
  },
  returns: {
    url: `${defaultCustomerRoute}/returns`,
    name: 'Returns',
  },
  addresses: {
    url: `${defaultCustomerRoute}/addresses`,
    name: 'Addresses',
  },
  wishlist: {
    url: `${defaultCustomerRoute}/wishlist`,
    name: 'Wishlist',
  },
}
