import(/* webpackMode: "eager" */ "/app/app/i18n/client.ts");
import(/* webpackMode: "eager" */ "/app/components/common/Footer/Footer.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/Navbar/Navbar.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/Notification/Notification.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/SearchAutocompleteProducts/SearchAutocompleteProducts.tsx");
import(/* webpackMode: "eager" */ "/app/components/common/Searchbar/Searchbar.tsx");
import(/* webpackMode: "eager" */ "/app/components/header/SubHeader/SubHeader.tsx");
import(/* webpackMode: "eager" */ "/app/components/ui/Link/Link.tsx");
import(/* webpackMode: "eager" */ "/app/components/widgets/AbstractWidget.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/lib/Hydrate.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/lib/isRestoring.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/lib/QueryClientProvider.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/lib/QueryErrorResetBoundary.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/lib/reactBatchedUpdates.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/lib/useInfiniteQuery.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/lib/useIsFetching.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/lib/useIsMutating.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/lib/useMutation.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/lib/useQueries.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/lib/useQuery.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js")